<template>
  <form @submit.prevent="submit">
    <div class="row">
      <loading-notification :show="loading || verifying" />
    </div>
    <div class="row">
      <div class="flex xs12 mti-row">
        <team-bar
          :loading="loading"
          :disabled="loading"
          :with-filters="teamFilter"
          :initial-team="initialTeam"
          @selected-team="teamSelected"
        />
      </div>
      <div
        v-show="
          selectedTeam &&
          !isLoading.preachingPoints &&
          preachingPointsList.length > 0
        "
        class="flex xs12"
      >
        <h6>{{ $t("mti.inputs.preaching_points_needed") }}</h6>
      </div>
      <div v-show="selectedTeam" class="flex xs12">
        <div class="row">
          <loading-notification :show="isLoading.preachingPoints" />
          <div
            class="flex xs12 sm6 md4" style="overflow: auto; max-height: 294px;"
            v-for="point in preachingPointsList"
            :key="point.id"
          >
            <va-checkbox
              :label="point.name"
              :disabled="loading || isLoading.preachingPoints"
              v-model="point.isPart"
            />
          </div>
        </div>
      </div>
    </div>
    <location-bar
      :loading="loading"
      :with-filters="locationFilters"
      :initial-district="initialDistrict"
      @selected-district="adjustDistrict"
    />
    <div class="row">
      <div class="flex xs12 md4">
        <va-input
          color="info"
          v-model="form.name.value"
          :disabled="loading"
          :label="$t('mti.inputs.churchName')"
          :error="!!form.name.errors.length"
          :error-messages="translatedErrors(form.name.errors)"
          @blur="validate('name')"
        />
      </div>
      <div class="flex xs12 md4">
        <va-date-picker
          id="date-input"
          :label="$t('mti.inputs.plantedDateInput')"
          v-model="mtiDate"
        />
      </div>
      <div class="flex xs12 md4">
        <va-input
          color="info"
          type="number"
          step="1"
          v-model="form.assistance.value"
          :disabled="loading"
          :label="$t('mti.inputs.assistanceInput')"
          :error="!!form.assistance.errors.length"
          :error-messages="translatedErrors(form.assistance.errors)"
          @blur="validate('assistance')"
        />
      </div>
    </div>
    <div class="row">
      <div class="flex xs12">
        <user-bar
          :label="$t('mti.inputs.ministerInput')"
          :loading="loading"
          :disabled="loading"
          :initial-user="initialMinister"
          @selected-user="ministerSelected"
        />
        <!-- <va-select
          v-model="selectedMinister"
          :label="$t('mti.inputs.ministerInput')"
          :options="ministersList"
          :loading="isLoading.ministers"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
          text-by="name"
          searchable
        /> -->
      </div>
    </div>
        <div class="row">
      <div class="flex xs12">
        <va-input
          color="info"
          v-model="form.address.value"
          :min-rows="5"
          :autosize="true"
          :disabled="loading"
          :label="$t('layout.form.addressInput')"
          :error="!!form.address.errors.length"
          :error-messages="translatedErrors(form.address.errors)"
          @blur="validate('address')"
        />
      </div>
    </div>
    <div class="row">

      <div class="flex xs12 md4">
        <va-input
          :label="$t('label.latitude')"
          :autosize="true"
          :disabled="loading"
          v-model="form.latitude.value"
          @blur="validate('label.latitude')"
        />
      </div>
            <div class="flex xs12 md4">
        <va-input
          color="info"
          :autosize="true"
          :disabled="loading"
          v-model="form.longitude.value"
          :label="$t('label.longitude')"
        />
      </div>
      <div class="flex xs12 md4">
        <va-button class="buttonContainer" color="primary"  @click.prevent="getCurrentLongitudeLatitude" style="margin: 0;"
          >{{ $t('button.coordinates') }}</va-button
        />
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <div class="row">
          <div class="flex xs8">
            <p>{{ $t("mti.inputs.hasMother") }}</p>
          </div>
          <div class="flex xs2">
            <va-radio-button
              :option="true"
              v-model="hasMother"
              :label="$t('layout.yes')"
            />
          </div>
          <div class="flex xs2">
            <va-radio-button
              :option="false"
              v-model="hasMother"
              :label="$t('layout.no')"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-show="hasMother">
      <div class="flex xs12">
        <va-input
          color="info"
          v-model="motherMinister"
          :disabled="loading"
          :label="$t('mti.inputs.motherMinisterInput')"
        />
      </div>
      <div class="flex xs12">
        <va-input
          color="info"
          v-model="motherChurch"
          type="textarea"
          :min-rows="5"
          :autosize="true"
          :disabled="loading"
          :label="$t('mti.inputs.motherChurchInput')"
        />
      </div>
    </div>

    <div class="row" v-if="!wizard">
      <div class="flex xs12">
        <va-button color="primary" :disabled="loading || notValid || verifying">
          <text-loading :loading="loading" icon="fa fa-save">
            {{ $t("layout.form.save") }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import { validatorMixin } from "@/services/validator";
const LocationBar = () =>
  import(/* webpackPrefetch: true */ "@/components/extras/Bars/Location");
const TeamBar = () =>
  import(/* webpackPrefetch: true */ "@/components/extras/Bars/Team");
const UserBar = () =>
  import(/* webpackPrefetch: true */ "@/components/extras/Bars/User");

export default {
  name: "mti-form",
  components: {
    LocationBar,
    TeamBar,
    UserBar,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    wizard: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    mti: {
      type: Object,
      required: false,
      default: function () {
        return {
          id: 0,
        };
      },
    },
  },
  mixins: [validatorMixin],
  computed: {
    ...mapGetters(["currentUser", "currentLocale"]),
    notValid() {
      return !this.selectedMinister || this.noRegionalData;
    },
    isNew() {
      return this.mti.id === 0;
    },
  },
  data() {
    return {
      latitude: "",
      longitude: "",
      verifying: false,
      assignedError: false,
      noRegionalData: false,
      isLoading: {
        ministers: false,
        preachingPoints: false,
      },
      isError: {
        ministers: false,
        preachingPoints: false,
      },
      preachingPointsList: [],
      mtiDate: null,
      // ministersList: [],
      initialMinister: null,
      selectedMinister: "",
      initialTeam: null,
      selectedTeam: null,
      locationFilters: ["regions", "countries", "districts"],
      initialDistrict: null,
      selectedDistrict: null,
      hasMother: false,
      motherChurch: "",
      motherMinister: "",
      teamFilter: "",
      form: {
        name: {
          value: "",
          validate: {
            required: true,
          },
          errors: [],
        },
        assistance: {
          value: 0,
          validate: {
            required: true,
          },
          errors: [],
        },
        address: {
          value: "",
          validate: {
            required: true,
          },
          errors: [],
        },
        latitude: {
          value: "",
          validate: {
            required: true,
          },
          errors: [],
        },
        longitude: {
          value: "",
          validate: {
            required: true,
          },
          errors: [],
        },
      },
    };
  },
  watch: {
    mti(val) {
      this.setMti(val);
    },
    selectedCountry(val) {
      this.checkRegionalData();
    },
    selectedDistrict(val) {
      this.checkRegionalData();
    },
    currentLocale(val) {
      this.validateAll();
    },
    "currentUser.permissions"(val) {
      this.initialData();
    },
  },
  created() {
    this.initialData();
    this.$nextTick(() => {
      this.validateAll();
    });
  },
  methods: {
    async setMti(val) {
      this.setFormData(val);

      if (val.minister) {
        this.initialMinister = val.minister;
      }
      /*       if (val.minister) {
        this.selectedMinister = val.minister
      } */
      if (val.team) {
        this.initialTeam = val.team;
      }

      if (val.district) {
        const district = Object.assign({}, val.district);
        this.selectedDistrict = district;
        this.initialDistrict = this.selectedDistrict;
      }

      if (val.planted_date) {
        this.mtiDate = val.planted_date;
      }

      if (val.mother_church && val.mother_church_minister) {
        this.motherChurch = val.mother_church;
        this.motherMinister = val.mother_church_minister;
        this.hasMother = true;
      }

      if (val.longitude) {
        this.form.longitude.value = val.longitude;
      }

      if (val.latitude) {
        this.form.latitude.value = val.latitude;
      }

      if (val.id) {
        await this.$nextTick();
        this.validateAll();
      }
    },
    ministerSelected(minister) {
      this.selectedMinister = minister;
    },
    getPeriod() {
      const date = new Date();
      const month = date.getMonth();
      let yearBefore = date.getFullYear();

      if (month < 9) {
        yearBefore -= 1;
      }

      return yearBefore;
    },
    fixPreachingPoints(data) {
      const points = [];
      for (const d of data) {
        const point = {
          id: d.id,
          name: `${d.name} (${d.code})`,
          isPart: false,
        };
        point.isPart = this.mtiIn(d.id);
        points.push(point);
      }
      return points;
    },
    mtiIn(pointId) {
      if (!this.mti || !this.mti.proyections) return false;
      const isIn = this.mti.proyections.find((g) => g.id === pointId);

      return isIn !== undefined;
    },
    teamSelected(team) {
      this.selectedTeam = team;
      if (team) {
        this.getTeamProyections(team);
        this.adjustTeamData(team);
      }
    },
    checkRegionalData() {
      this.noRegionalData = !this.selectedDistrict;
    },
    initialData() {
      if (this.mti) {
        this.setMti(this.mti);
      }
      this.asyncFindMinister("&sort=name&direction=asc");
    },
    async asyncFindMinister(query) {
      if (this.selectedCountry) {
        query += "&country=" + this.selectedCountry.id;
      }
      await this.asyncUsers(query, "ministers", "users");
      if (this.isError.ministers) {
        this.asyncFindMinister(query);
      }
    },
    async asyncUsers(query, type, route) {
      this.isLoading[type] = true;
      this.isError[type] = false;

      if (!query.includes("&sort=")) {
        query += "&sort=name";
      }
      if (!query.includes("&direction=")) {
        query += "&direction=asc";
      }

      const r = `/${route}?group=${query}`;
      let response = false;
      try {
        response = await this.$http.get(r);
      } catch (err) {
        this.isLoading[type] = false;
        this.isError[type] = true;
        return;
      }

      this[type + "List"] = response.data.data || [];
      this.isLoading[type] = false;
    },
    async asyncFind(query, type, route) {
      this.isLoading[type] = true;
      this.isError[type] = false;

      if (!query.includes("&sort=")) {
        query += "&sort=name";
      }
      if (!query.includes("&direction=")) {
        query += "&direction=asc";
      }

      let response = false;
      try {
        response = await this.$http.get(`/${route}?q=${query}`);
      } catch (err) {
        this.isLoading[type] = false;
        this.isError[type] = true;
        return;
      }

      this.isLoading[type] = false;
      return response.data.data || [];
    },
    adjustDistrict(district) {
      this.selectedDistrict = district;
      this.asyncFindMinister("&district=" + district.id);
      this.getTeamsDistrictBased(district);

      if (this.selectedTeam && this.selectedTeam.district_id !== district.id) {
        // this.selectedTeam = null
      }
    },

    getCurrentLongitudeLatitude() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.form.latitude.value = parseFloat(position.coords.latitude);
          this.form.longitude.value = parseFloat(position.coords.longitude);
          console.log(this.form.latitude.value, this.form.longitude.value);
        });
      }
    },

    async getTeamProyections(team) {
      const period = this.getPeriod();
      let query = `&date_since=${period}-10-01`;
      if (team) {
        if (team.district_id) {
          query += `&district=${team.district_id}`;
        }
        if (team.country_id) {
          query += `&country=${team.country_id}`;
        }
        if (team.region_id) {
          query += `&region=${team.region_id}`;
        }
      }
      const list = await this.asyncFind(
        query,
        "preachingPoints",
        "preaching-points"
      );
      this.preachingPointsList = this.fixPreachingPoints(list);
      if (this.isError.preachingPoints) {
        this.getTeamProyections(team);
      }
    },
    adjustTeamData(team, id) {
      if (team && team.district) {
        this.selectedDistrict = team.district;
        this.initialDistrict = this.selectedDistrict;
      }
    },
    getTeamsDistrictBased(district) {
      this.teamFilter = "&district=" + district.id;
    },
    async submit() {
      if (!this.isNew) {
        if (this.selectedMinister === null) {
          this.showToast(this.$t("notifications.auth.minister_needed"), {
            icon: "fa-times",
            position: "top-right",
          });
          return;
        }
      }

      if (this.selectedTeam === null) {
        this.showToast(this.$t("notifications.auth.team_needed"), {
          icon: "fa-times",
          position: "top-right",
        });
        return;
      }

      this.validateAll();
      if (!this.formReady) return;

      const mti = this.getFormData(this.mti);
      if (this.selectedDistrict) {
        mti.district_id = this.selectedDistrict.id;
      }
      if (this.selectedMinister) {
        mti.minister_id = this.selectedMinister.id;
      }
      if (this.selectedTeam) {
        mti.team_id = this.selectedTeam.id;
      }
      if (this.mtiDate) {
        mti.planted_date = this.mtiDate;
      }
      mti.mother_church = this.hasMother === true ? this.motherChurch : null;
      mti.mother_church_minister =
        this.hasMother === true ? this.motherMinister : null;

      let preachingPoints = this.preachingPointsList.map((x) =>
        x.isPart ? x.id : null
      );
      preachingPoints = preachingPoints.filter((x) => !!x);

      delete mti.district;
      delete mti.minister;
      delete mti.submitter;
      delete mti.preaching_points;

      this.assignedError = false;
      this.$emit("submit", { mti, preachingPoints });
    },
  },
};
</script>
<style lang="scss" scoped>
.mti-row {
  overflow: auto;
  max-height: 294px;
}
.buttonContainer {
  margin: 0 !important;
}